<template>
	<div>
        
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 当前 URL
            currentUrl: window.location.href,
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        // 在 MetaMask 打开
        this.openInMetaMask();
    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        // 在 MetaMask 打开
        openInMetaMask() {
            // 需要执行的环境
            let envs = ['devnet', 'testnet', 'prod'];
            if(this.responsive == 'mobile' && envs.indexOf(this.$config.env) > -1) {
                console.log('The mobile phone is detected, open in MetaMask...');
                setTimeout(() => {
                    // window.open(`https://metamask.app.link/dapp/${encodeURIComponent(this.currentUrl)}`);
                    window.open(`metamask://dapp/${encodeURIComponent(this.currentUrl)}`);
                }, 3000);
            }
        }
    }
}
</script>
<style scoped>

</style>