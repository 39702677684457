<template>
    <div>
        <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
            <template v-slot:activator="{ props }">
                <div v-bind="props" class="pointer">
                    <div v-if="responsive != 'pc'">
                        <v-icon icon="mdi mdi-dots-vertical" size="28" color="black01"></v-icon>
                    </div>
                    <div v-if="app">
                        <div v-if="app == 'Tianming'">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link>
                                <v-card-text class="pa-1 d-flex align-center">
                                    <Logo type="Tianming" app="Tianming" responsive='pc' :color="darkTheme == 1 ? 'dark' : 'light'" size="140"></Logo>
                                    <v-icon class="ml-1" :size="24">mdi mdi-chevron-down</v-icon>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="app == 'unCryptos'">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link>
                                <v-card-text class="pa-1 d-flex align-center">
                                    <Logo type="unCryptos" app="unCryptos" responsive='pc' :color="darkTheme == 1 ? 'dark' : 'light'" size="150"></Logo>
                                    <v-icon class="ml-1" :size="24">mdi mdi-chevron-down</v-icon>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="app == 'unNFTs'">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link>
                                <v-card-text class="pa-1 d-flex align-center">
                                    <Logo type="unNFTs" app="unNFTs" responsive='pc' :color="darkTheme == 1 ? 'dark' : 'light'" size="120"></Logo>
                                    <v-icon class="ml-1" :size="24">mdi mdi-chevron-down</v-icon>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div v-if="app == 'un'">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link>
                                <v-card-text class="pa-1 d-flex align-center">
                                    <Logo type="icon" app="un" size="28"></Logo>
                                    <span class="ml-2 font-weight-bold fs-24">Claim UN Tokens</span>
                                    <v-icon class="ml-1" :size="24">mdi mdi-chevron-down</v-icon>
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>
                </div>
            </template>
            <v-card class="pa-2 mt-2" color="ffffff-2f2f2f" rounded="xl" width="300">
                <v-list class="flex" bg-color="ffffff-2f2f2f">
                    <v-row no-gutters>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link :href="$config.env == 'testnet' ? 'https://untrading.org/ai' : '/ai'">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="untrading" size="28"></Logo>
                                    <span class="ml-2">Tianming</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link :href="$config.env == 'testnet' ? 'https://untrading.org/uncryptos' : '/uncryptos'">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="unCryptos" size="28"></Logo>
                                    <span class="ml-2">unCryptos</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link :href="$config.env == 'testnet' ? 'https://untrading.org/unnfts' : '/unnfts'">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="unNFTs" size="28"></Logo>
                                    <span class="ml-2">unNFTs</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link href="https://testnet.untrading.org/uncryptos">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="unCryptos" env="testnet" size="28"></Logo>
                                    <span class="ml-2">unCryptos Racetrack Testnet</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link href="https://testnet.untrading.org/unnfts">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="unNFTs" env="testnet" size="28"></Logo>
                                    <span class="ml-2">unNFTs Testnet</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="pa-0">
                            <v-card class="pa-1" rounded="lg" elevation="0" color="transparent" :ripple="false" link :href="$config.env == 'testnet' ? 'https://untrading.org/un' : '/un'">
                                <v-card-text class="pa-2 d-flex align-center">
                                    <Logo type="icon" app="un" size="28"></Logo>
                                    <span class="ml-2">Claim UN Tokens</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    props: {
        // 图标大小
        size: {
            type: [Number, String],
            required: false,
            default: 150
        },
        // 应用
        app: {
            type: String,
            required: false,
            default: null
        },
    },
    data(){
        return {
            menu: false,
        }
    },
    components: { Logo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'responsive']),
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>