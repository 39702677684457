<template>
  <v-app class="h-100">
    <!-- v-if="responsive == 'mobile'"  -->
    <!-- <div class="h-100" v-if="responsive == 'mobile'" >
        <Mobile></Mobile>
    </div> -->
    <!-- v-else -->
    <div class="h-100">
        <metainfo>
            <template v-slot:title="{ content }">{{ content ? `${content} | untrading` : `untrading` }}</template>
        </metainfo>
        <div v-if="responsive == 'pc'">
            <HeaderHome v-if="headerHomeURLs.indexOf($route.path) !== -1"></HeaderHome>
            <HeaderAI v-else-if="headerAIURLs.indexOf($route.path) !== -1"></HeaderAI>
            <HeaderApp v-else></HeaderApp>
        </div>
        <div v-if="responsive == 'mobile'">
            <HeaderMobileHome v-if="headerHomeURLs.indexOf($route.path) !== -1"></HeaderMobileHome>
            <HeaderMobileAI v-else-if="headerAIURLs.indexOf($route.path) !== -1"></HeaderMobileAI>
            <HeaderMobileApp v-else></HeaderMobileApp>
        </div>
        <v-main id="main">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name"/>
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name"/>
          </router-view>
        </v-main>
        <BottomNavigationMobile :pathData="notBottomNavigationURLs" :pathState="false" v-if="responsive == 'mobile'"></BottomNavigationMobile>
        <Footer></Footer>
        <Web3js></Web3js>
        <!-- <OpenInMetaMask></OpenInMetaMask> -->
        <!-- <BackToTop></BackToTop> -->
        <!-- <Cookies></Cookies> -->
        <!-- <WelcomeDialog></WelcomeDialog> -->
    </div>
  </v-app>
</template>

<script>
import { useMeta } from 'vue-meta'
import Mobile from '@/components/common/Mobile';
import HeaderHome from '@/components/common/header/v4/HeaderHome';
import HeaderAI from '@/components/common/header/v4/HeaderAI';
import HeaderApp from '@/components/common/header/v4/HeaderApp';
import HeaderMobileHome from '@/components/common/header/v4/HeaderMobileHome';
import HeaderMobileAI from '@/components/common/header/v4/HeaderMobileAI';
import HeaderMobileApp from '@/components/common/header/v4/HeaderMobileApp';
import BottomNavigationMobile from '@/components/common/mobile/BottomNavigationMobile';
import Footer from '@/components/common/Footer';
import Web3js from "@/components/util/Web3js";
import Cookies from '@/components/common/Cookies';
import WelcomeDialog from '@/components/common/WelcomeDialog';
import BackToTop from '@/components/common/BackToTop';
import OpenInMetaMask from '@/components/scheme/OpenInMetaMask';
import { mapGetters } from "vuex";
import UserAPI from '@/api/user';
import SetAPI from '@/api/set.js';
import CollectionAPI from '@/api/collection.js';
import ForwardAPI from '@/api/forward.js';
import PriceAPI from '@/api/price.js';
import VisitAPI from '@/api/visit.js';
import CryptoAPI from '@/api/crypto.js';
import CryptoProductAPI from '@/api/crypto-product.js';
import CryptoProfitExpectancyAPI from '@/api/crypto-profit-expectancy.js';
export default {
    name: 'App',
    data: () => ({
        // 不使用手机底部的 URL
        notBottomNavigationURLs: ['/', '/welcome', '/ai', '/kols', '/network-defi','/about', '/features', '/connectwallet', '/un','/community-empowerment','/innovation','/shared-rewards','/no-commission','/fr-calculator'],
        // 使用主页头的 URL
        headerHomeURLs: ['/', '/welcome', '/kols', '/network-defi','/about', '/features', '/un','/community-empowerment','/innovation','/shared-rewards','/no-commission','/fr-calculator'],
        // 使用 AI 头的 URL
        headerAIURLs: ['/ai']
    }),
    components: { Mobile, HeaderHome, HeaderAI, HeaderApp, HeaderMobileHome, HeaderMobileAI, HeaderMobileApp, Footer, Web3js, Cookies, WelcomeDialog, BackToTop, OpenInMetaMask, BottomNavigationMobile },
    computed: {
        ...mapGetters(['token', 'user', 'responsive'])
    },
    created() {
        // 查询当前登录的用户对象
        this.getMe();
        // 查询支付代币的价格
        // this.getPaymentTokenPrice();
        // 查询货币价格
        this.getPrices();
        // 记录一次访问
        this.visit();
        // 监听更新资料事件
        this.$bus.on('emitGetMe', data => {
            this.getMe();
        })
        // 监听更新套装事件
        this.$bus.on('emitSets', data => {
            this.sets();
        })
        // 监听更新收藏夹事件
        this.$bus.on('emitCollections', data => {
            this.collections();
        })
        // 判断用户是否有加密货币封装权限
        this.wrapPermission();
        // 查询加密货币全部产品
        this.getCryptoProducts();
        // 查询全部加密货币盈利预期
        this.getCryptoProfitExpectancies();
    },
    mounted(){
        // useMeta({
        //     title: this.$config.title,
        //     link:[
        //         { rel: 'canonical', href: this.$config.domain, id: 'canonical' },
        //     ],
        //     meta: [
        //         { property: 'og:description', content: "Untrading is redefining how users engage with DeFi by leveraging its own ERC-5173 protocol to create a fair and transparent trading ecosystem that fosters collaboration over competition. Untrading creates a genuine win-win model where communities build sustainable wealth together."},
        //         { property: 'og:keyword', content: "ERC-5173, non-fungible Future Reward protocol (nFR), Future Rewards (FRs), Flows, Originators' Rewards (ORs), No zero-sum, on-chain NFT licenses, Web3, DAOs, Decentralized Finance (DeFi), Decentralized Exchange (DEX), Non-Fungible Tokens (NFTs), on-chain royalty payments, Tokenization, Ethereum Network, Arbitrum One Network, Polygon Network, Trustless, Smart Contracts, Cryptocurrency, Digital Assets,非同质化未来奖励协议, 未来奖励, 流体系, 礼物链, 发起人奖励, 反零和交易, NFT 链上版权, 去中心化自治组织, 去中心化金融, 去中心化交易所, 非同质化代币, 链上版税, 代币化, 以太坊网络, 多边形网络, 无需信任, 去中心化, 智能合约, 加密货币, 数字资产"},
        //         { property: 'og:url', content: this.$config.domain},
        //         { property: 'og:title', content: this.$config.title},
        //         { property: 'og:type', content: 'website'},
        //         { property: 'twitter:site', content: this.$config.domain},
        //         { property: 'twitter:url', content: this.$config.domain},
        //         { property: 'twitter:title', content: this.$config.title},
        //         { property: 'twitter:card', content: 'summary_large_image'},
        //         { property: 'twitter:description', content: "Untrading is redefining how users engage with DeFi by leveraging its own ERC-5173 protocol to create a fair and transparent trading ecosystem that fosters collaboration over competition. Untrading creates a genuine win-win model where communities build sustainable wealth together."},
        //     ]
        // })
    },
    methods: {
        // 查询当前登录的用户对象
        getMe(){
            if (this.token != null) {
                UserAPI.getMe().then(res => {
                    let data = res.data
                    if (data.success) {
                        this.$store.dispatch("userHandler", data.data.user);
                        this.$store.dispatch('walletAddressHandler', data.data.user.wallet);
                        this.$store.dispatch('walletTypeHandler', data.data.walletType);
                        // 查询当前用户的套装 
                        this.sets();
                        // 根据用户名查询所有的收藏夹
                        this.collections();
                    } else {
                        // 失败了则清除token重新加载
                        this.$store.dispatch("tokenHandler", null);
                        this.$router.push("/");
                        setTimeout(() => {
                            // 重新加载
                            location.reload();
                        }, 10)
                    }
                }).catch(() => {
                    this.$store.dispatch("userHandler", {});
                    next({ name: 'CollectWallet' })
                });
            }
        },
        // 根据用户名查询所有的套装
        async sets() {
            let res = await SetAPI.sets(this.user.username, null);
            let data = res.data;
            if(data.success) {
                this.$store.dispatch("setsHandler", data.data);
            }
        },
        // 根据用户名查询所有的收藏夹
        async collections() {
            let res = await CollectionAPI.collections(this.user.username, null);
            let data = res.data;
            if(data.success) {
                this.$store.dispatch("collectionsHandler", data.data);
            }
        },
        // 查询支付代币的价格
        async getPaymentTokenPrice() {
            // Coin List API: https://api.coingecko.com/api/v3/coins/list
            // ETH: ethereum
            // MATIC: matic-network
            let res = await ForwardAPI.forwardGet('https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum,matic-network');
            let data = res.data;
            let paymentTokenPrice = {
                ETH: data['ethereum'] ? data['ethereum'].usd : 0,
                MATIC: data['matic-network'] ? data['matic-network'].usd : 0,
            }
            this.$store.dispatch('paymentTokenPriceHandler', paymentTokenPrice);
        },
        // 查询货币价格
        async getPrices() {
            let res = await PriceAPI.getPrices(null);
            let data = res.data;
            this.$store.dispatch('pricesHandler', data.data);
        },
        // 记录一次访问
        async visit() {
            await VisitAPI.visit();
        },
        // 判断用户是否有封装权限
        async wrapPermission() {
            let res = await CryptoAPI.wrapPermission();
            let data = res.data;
            if(data.success) {
                this.$store.dispatch('cryptoWrapPermissionHandler', data.data);
            }
        },
        // 查询加密货币全部产品
        async getCryptoProducts() {
            let res = await CryptoProductAPI.getProducts();
            let data = res.data;
            if(data.success) {
                this.$store.dispatch('cryptoProductsHandler', data.data);
            }
        },
        // 查询全部加密货币盈利预期
        async getCryptoProfitExpectancies() {
            let res = await CryptoProfitExpectancyAPI.getCryptoProfitExpectancies();
            let data = res.data;
            if(data.success) {
                this.$store.dispatch('cryptoProfitExpectanciesHandler', data.data);
            }
        },
    }
}
</script>
<style scoped >
/* #app{
    -webkit-overflow-scrolling: touch;
    position:absolute;
    z-index:1;
} */
.header-float {
    position:absolute;
    z-index:1;
}
</style>